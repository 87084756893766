<script lang="ts" setup>
import { ref } from 'vue';
import ChallengeCard from "@components/vue/home/challenges-section/nested-components/challenge-card/index.vue";
import CardModal from "@components/vue/home/challenges-section/nested-components/card-modal/index.vue";

type Card = {
  photo: string;
  title: string;
  description: string;
  text: string;
  next: string;
  previous: string;
  variant: string;
};

type TextToShow = {
  title: string;
  word: string;
  text: string;
};

const props = defineProps<{
  listOfCards: Card[],
  staticText: TextToShow,
}>();

const selectedCard = ref<Card>();
const isModalVisible = ref(false);
const indexOfSelectedCard = ref(0);

const listOfCardsId = `list-of-cards-${Math.random().toString(36).substr(2, 9)}`;

const openModal = (card: Card, index: number) => {
  selectedCard.value = card;
  indexOfSelectedCard.value = index;
  isModalVisible.value = true;
};

const close = () => {
  isModalVisible.value = false;
  selectedCard.value = undefined;
};

const next = () => {
  indexOfSelectedCard.value = indexOfSelectedCard.value + 1;
  selectedCard.value = (props.listOfCards ?? [])[indexOfSelectedCard.value];
};

const previous = () => {
  indexOfSelectedCard.value = indexOfSelectedCard.value - 1;
  selectedCard.value = (props.listOfCards ?? [])[indexOfSelectedCard.value];
};
</script>

  <template>
    <div class="challenges">
      <h1 class="challenges__title">
        {{ staticText?.title }}
        <span class="challenges__title__word">
          {{ staticText?.word }}
        </span>
      </h1>
      <p class="challenges__text">{{ staticText?.text }}</p>
      <div class="challenges__actionable-area" :id="listOfCardsId">
        <ChallengeCard
          v-for="(card, index) in listOfCards"
          :key="index"
          :photo="card.photo"
          :title="card.title"
          :description="card.description"
          :text="card.text"
          :id="index"
          :variant="card.variant"
          @open-modal:mobile="openModal(card, index)"
          class="challenges__actionable-area__card"
        />
      </div>
      <CardModal v-if="isModalVisible && selectedCard" :item="selectedCard" @close="close" @next="next" @previous="previous" :initial-index="indexOfSelectedCard"/>
    </div>
</template>

<style scoped>
  .challenges {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    padding: 2.5rem 1.25rem;
    max-width: 120rem;

    & .challenges__title {
      font-family: "Suisse Intl", sans-serif;
      font-size: clamp(1.5rem, 0.786rem + 3.571vw, 4rem);
      font-weight: 300;
      line-height: clamp(2rem, 1.089rem + 4.554vw, 5.188rem);
      letter-spacing: clamp(0em, 0.912rem + 0.376vw, -0.02em);
      text-align: left;
      color: #8d8da5;
      max-width: 45.625rem;

      & .challenges__title__word {
        font-family: "Suisse Intl", sans-serif;
        font-size: clamp(1.5rem, 0.786rem + 3.571vw, 4rem);
        font-style: italic;
        font-weight: 500;
        line-height: clamp(2rem, 1.089rem + 4.554vw, 5.188rem);
        letter-spacing: clamp(0em, 0.912rem + 0.376vw, -0.02em);
        text-align: left;
        color: #2d2d39;
      }
    }

    & .challenges__text {
      font-family: "Suisse Intl", sans-serif;
      font-size: clamp(0.875rem, 0.696rem + 0.893vw, 1.5rem);
      font-weight: 400;
      line-height: clamp(1.5rem, 1.357rem + 0.714vw, 2rem);
      text-align: left;
      color: #2d2d39;
      max-width: 39.375rem;
    }

    & .challenges__actionable-area {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1.5rem;

      & .challenges__actionable-area__card {
        color: #2d2d39;

        &.challenges__actionable-area__card--desktop {
          display: none;
        }

        &.challenges__actionable-area__card--mobile-and-tablet {
          display: flex;
        }
      }
    }
  }

  @media (min-width: 720px) and (max-width: 1024px) {
    .challenges {
      padding: 6.5rem 2.5rem;

      & .challenges__actionable-area {
        grid-template-columns: repeat(2, 1fr);

        column-gap: 9rem;
        row-gap: 2.5rem;

        & .challenges__actionable-area__card {
          &.challenges__actionable-area__card--desktop {
            display: none;
          }

          &.challenges__actionable-area__card--mobile-and-tablet {
            display: flex;
          }
        }
      }
    }
  }

  @media (min-width: 1025px) {
    .challenges {
      padding: 6.5rem 5rem;

      & .challenges__actionable-area {
        grid-template-columns: repeat(3, 1fr);

        gap: 2.5rem;

        & .challenges__actionable-area__card {
          &.challenges__actionable-area__card--desktop {
            display: flex;
          }

          &.challenges__actionable-area__card--mobile-and-tablet {
            display: none;
          }
        }
      }
    }
  }
</style>
