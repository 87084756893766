<script setup lang="ts">
import { defineProps } from 'vue'

const props = withDefaults(
	defineProps<{
		path: string;
		viewBox?: string;
		width?: string;
        height?: string;
        class?: string;
	}>(),
	{
		viewBox: "0 0 24 24",
		width: "24",
        height: "24",
        class: undefined
	},
);

</script>

<template>
    <ods-icon :class="props?.class" :path="props.path" :view-box="props.viewBox" :width="props.width" :height="props.height" fill-rule="evenodd" clip-rule="evenodd" stroke-width="0" fill-color="currentColor">
        <slot />
    </ods-icon>
</template>
