<script lang="ts" setup>
import { defineProps, defineEmits } from 'vue';
import IconButton from "@components/vue/icon-button/index.vue";
import Icon from "@components/vue/icon/index.vue";

import { getIcon } from '@orbyn/design-system-icons';

type Challenge = {
  title: string;
  description: string;
  text: string;
  next: string;
  previous: string;
};

const properties = defineProps<{
  item: Challenge,
  initialIndex: number,
}>();

const emit = defineEmits(['close', 'next', 'previous']);
</script>

<template>
  <div class="card-modal"
    @drag.prevent
  >
    <div class="card-modal__content">
      <div class="card-modal__content__header">
        <h3 class="card-modal__content__header__title">{{ properties.item.title ?? ""}}</h3>
        <IconButton
          :icon="getIcon('x-close')"
          size="large"
          id="menuButton"
          class="card-modal__content__header__close-button"
          @click="emit('close')"
        />
      </div>
      <span class="card-modal__content__divider"></span>
      <div class="card-modal__content__body">
        <div class="card-modal__content__body__wrapper">
          <p class="card-modal__content__body__description">{{ properties.item.text ?? "" }}</p>
        </div>
      </div>
      <div class="card-modal__content__footer">
        <button class="card-modal__content__footer__button card-modal__content__footer__button--previous" @click="emit('previous')" :disabled="initialIndex === 0">
          <Icon
            :path="getIcon('arrow-narrow-left')"
            class="card-modal__content__footer__button__icon"
          />
          {{ properties.item.previous ?? "" }}
        </button>
        <button class="card-modal__content__footer__button card-modal__content__footer__button--next" @click="emit('next')" :disabled="initialIndex === 5">
          {{ properties.item.next ?? "" }}
          <Icon
            :path="getIcon('arrow-narrow-right')"
            class="card-modal__content__footer__button__icon"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .card-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #2d2d39cc;
    backdrop-filter: blur(1rem);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;

    & .card-modal__content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 1.25rem;
      width: 100%;
      max-width: 500px;

      & .card-modal__content__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & .card-modal__content__header__title {
          font-family: "Suisse Intl", sans-serif;
          font-size: clamp(1.25rem, 1.036rem + 1.071vw, 2rem);
          font-weight: 500;
          line-height: clamp(1.75rem, 1.679rem + 0.357vw, 2rem);
          text-align: left;
          text-transform: uppercase;
          color: #fff;
        }

        & .card-modal__content__header__close-button {
          cursor: pointer;
          color: #fff;
        }
      }

      & .card-modal__content__divider {
        width: 100%;
        height: 1px;
        background-color: #fff;
      }

      & .card-modal__content__body {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        background-color: white;
        height: 24rem;
        max-height: calc(100vh - 10rem);

        & .card-modal__content__body__wrapper {
          max-height: calc(100vh - 10rem);
          overflow-y: auto;
          overflow-x: hidden;
        }

        & .card-modal__content__body__description {
          font-family: "Suisse Intl", sans-serif;
          font-size: 16px;
          font-weight: 300;
          line-height: 24px;
          text-align: left;
          color: #2D2D39;
          padding: 1rem;
        }
      }

      & .card-modal__content__footer {
        display: flex;
        justify-content: space-around;

        & .card-modal__content__footer__button {
          display: flex;
          align-items: center;
          gap: 1rem;
          color: white;
          border: none;
          cursor: pointer;

          font-family: "Suisse Intl", sans-serif;
          font-size: clamp(1rem, 0.929rem + 0.357vw, 1.25rem);
          font-weight: 500;
          line-height: clamp(1.25rem, 1.036rem + 1.071vw, 2rem);
          text-align: left;
          text-transform: uppercase;

          &:disabled {
            cursor: not-allowed;
            color: #C6C6D2;
          }

          & .card-modal__content__footer__button__icon {
            height: 1.5rem;
            width: 1.5rem;
          }
        }
      }
    }
  }

  @media (max-width: 720px) and (orientation: landscape) {
    .card-modal {
      & .card-modal__content {
        max-height: 100%;
        max-width: 100%;
      }
    }
  }
</style>
