<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue';
import Icon from "@components/vue/icon/index.vue";
import { getIcon } from '@orbyn/design-system-icons';

const isMobileOrTablet = ref(false);

const detectDevice = () => {
  const windowWidth = window.innerWidth;
  isMobileOrTablet.value = windowWidth <= 1024;
};

onMounted(() => {
  detectDevice();
  window.addEventListener('resize', detectDevice);
});

const props = defineProps<{
  photo: string,
  title: string,
  description: string,
  text: string,
  id: number,
  class: string,
  variant: string,
}>();

const cardClass = computed(() => `clickable-card ${props.class ?? ''}`.trim());
const emit = defineEmits(['open-modal:mobile']);

const checkIfIsMobileOrTabletComputed = computed(() => isMobileOrTablet.value);

const clickIfIsMobileOrTablet = () => {
  if (checkIfIsMobileOrTabletComputed.value) {
    emit('open-modal:mobile');
  }
  return null;
};

const variantComputedSelectorClass = computed(() => {
  return [`clickable-card__hover-content`, `clickable-card__hover-content--${props.variant}`];
});
</script>

<template>
  <div
    :class="cardClass"
    :id="`clickable-card-${props.id}`"
    @click="clickIfIsMobileOrTablet()"

  >
    <div class="clickable-card__image-container">
      <img
        v-if="props.photo"
        :src="props.photo"
        :alt="`${props.title}`"
        class="clickable-card__image"
      />

      <div :class="variantComputedSelectorClass">
        <p class="clickable-card__hover-content__description">{{ props.text }}</p>
      </div>
    </div>

    <span class="clickable-card__divider"></span>

    <div class="clickable-card__title-container">
      <h1 class="clickable-card__title-container__title">{{ props.title }}</h1>
      <Icon
        :path="getIcon('plus')"
        class="clickable-card__title-container__icon"
      />
    </div>

    <p class="clickable-card__description">{{ props.description }}</p>
  </div>
</template>

<style scoped>
  .clickable-card {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: left;
    font-family: "Suisse Intl", sans-serif;
    letter-spacing: clamp(0em, 0.912rem + 0.376vw, -0.02em);
    position: relative;

    & .clickable-card__image-container {
      position: relative;
      width: 100%;
      height: auto;
    }

    & .clickable-card__image {
      width: 100%;
      height: auto;
      transition: opacity 0.3s ease-in-out;
    }

    & .clickable-card__hover-content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.3s ease-in-out;
      overflow: hidden;

      & .clickable-card__hover-content__description {
        font-size: clamp(0.75rem, -0.485rem + 1.928vw, 1.25rem);
        font-weight: 300;
        line-height: 24px;
        color: #2D2D39;
        padding: 1rem;
        height: auto;
        overflow-y: auto;
      }

      &.clickable-card__hover-content--first {
        background-color: #F9F9FB;
      }

      &.clickable-card__hover-content--second {
        background-color: #F1F1F4;
      }

      &.clickable-card__hover-content--third {
        background-color: #E2E2E9;
      }

      &.clickable-card__hover-content--fourth {
        background-color: #C6C6D2;
      }

      &.clickable-card__hover-content--fifth {
        background-color: #A9A9BC;
      }

      &.clickable-card__hover-content--sixth {
        background-color: #8D8DA5
      }
    }

    & .clickable-card__divider {
      height: 2px;
      background-color: #2d2d39;
    }

    & .clickable-card__title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & .clickable-card__title-container__title {
        font-size: clamp(0.875rem, 0.686rem + 0.905vw, 1.5rem);
        font-weight: 500;
        line-height: clamp(1.5rem, 1.348rem + 0.724vw, 2rem);
        text-transform: uppercase;
      }

      & .clickable-card__title-container__icon {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    & .clickable-card__description {
      font-size: clamp(0.75rem, 0.523rem + 1.086vw, 1.5rem);
      font-weight: 400;
      line-height: clamp(1rem, 0.697rem + 1.448vw, 2rem);
    }

    @media (min-width: 1025px) {
      &:hover .clickable-card__image {
        opacity: 0;
      }

      &:hover .clickable-card__hover-content {
        opacity: 1;
        pointer-events: none;
      }

      & .clickable-card__title-container__icon {
        display: none;
      }
    }

    @media (max-width: 1024px) {
      pointer-events: all;

      & .clickable-card__hover-content {
        display: none;
      }

      & .clickable-card__description {
        display: block;
      }

      & .clickable-card__image {
        opacity: 1;
      }
    }
  }
</style>
